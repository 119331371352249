.layout {
	margin: 30px auto;
	max-width: 800px;

}

.layout-header {
	display: flex;
	gap: 10px;
	padding: 0 20px;
}

.layout-header > img {
	margin-top: -3px;
	object-fit: contain;
	width: 150px;
}

.layout-children {
	padding: 0 20px;
}