*:focus {
    outline: none;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.asRequest-tabButton {
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 13px;
    width: 50%;
}

.activeTabButton {
    background-color: #5fbd9e;
    color: white;
}

.asRequest-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.asRequest-inputs-div {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.asRequest-inputs > div > input {
    background: #ffffff;
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    outline: 1px solid #E9E9E7;
    padding: 15px 10px;
}

.asRequest-inputs > div > select {
    background: #ffffff;
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    outline: 1px solid #E9E9E7;
    padding: 15px 10px;
}

.input-required {
    color: red;
    margin-right: 10px;
}

.asRequest-checkBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.asRequest-checkBox-input {
    cursor: pointer;
}

.asRequest-subEmail {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.asRequest-subEmail-button {
    background-color: #fafafa;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    padding: 3px 5px;
}

.asRequest-subEmail-button:hover {
    background-color: #efefef;
}

.asRequest-button {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 50px;
}

.asRequest-button > button {
    background-color: #5fbd9e;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    max-width: 300px;
    padding: 20px 0;
    transition: all ease 0.1s 0s;
    width: 30vw;
}

.asRequest-button > button:hover {
    box-shadow: 5px 5px 0px 0px rgba(95, 189, 158, 0.25);
    transform: translate(-5px, -5px);
}

.asRequest-fileInput {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.filebox {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    width: 100%;
}

.filebox .upload-name {
    border: 1px solid #E9E9E7;
    border-radius: 5px;
    color: #6b6b6b;
    display: inline-block;
    height: 40px;
    padding: 5px 10px;
    vertical-align: middle;
    width: 80%;
}

.filebox label {
    background-color: #999999;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    max-width: 80px;
    padding: 17px 3vw;
    text-align: center;
    vertical-align: middle;
    width: 25%;
}

.filebox input[type="file"] {
    border: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;
}

.asRequest-inputs-div > span {
    color: #797979;
}

.asRequest-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;
}

.asRequest-images > img {
    border-radius: 5px;
    max-width: 24%;
    min-width: 130px;
}

.upload-name {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 70%;
}

.thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    margin: 10px 0;
}

.thumbnail-wrapper {
    border: 1px solid #efefef;
    border-radius: 5px;
    max-height: 24%;
    max-width: 24%;
    min-height: 130px;
    min-width: 130px;
    position: relative;
}

.thumbnail-wrapper img {
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.thumbnail-wrapper button {
    background-color: red;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
}

.thumbnail-wrapper button:hover {
    background-color: darkred;
}

.result-error-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0;
    color: #6b6b6b;
}

.result-asIdLabel {
    background-color: #f3f3f3;
    width: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.popup-key {
    font-size: 14px;
    color: #808080;
    margin-top: 10px;
    padding-bottom: 10px;
}

.popup-value {
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d2d2;
}

.popup-contents {
    margin: 0 0 30px 0;
}

.table-label {
    border-radius: 5px;
    padding: 3px 8px;
    font-weight: 400;
}

.popup-progress-step {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.popup-step {
    text-align: center;
    width: 25%;
    padding: 10px 10px;
    background-color: #d5d5d5;
    border-radius: 5px;
}

.popup-active {
    background-color: #5fbd9e;
    color: white;
    font-weight: bold;
}

.inquireResult {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid #d2d2d2;
    padding-top: 10px;
}

.file-download-button {
    padding: 7px 12px;
    background-color: #fafafa;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}

.file-download-button:hover {
    background-color: #d3d3d3;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 검정색 배경, 투명도 50% */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 다른 요소보다 위에 표시되도록 z-index 설정 */
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-content p {
    margin: 0;
    font-size: 18px;
}

textarea {
    width: calc(100% - 10px);
    height: 100px;
    resize: none;
    border: 1px solid #E9E9E7;
    border-radius: 5px;
    padding: 5px;
}

.address-popup {
    border: 1px solid #E9E9E7;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.asRequest-inputs-div > button {
    padding: 10px 10px;
    border: none;
    background-color: #999999;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}

.address-popup-close {
    border: none;
    background-color: #999;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
}

.finish-step {
    width: calc(100% - 30px);
    background-color: #5fbd9e;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    padding: 10px 15px;
}

.text {
    background-color: #efefef;
    padding: 20px;
    border-radius: 10px;
}

.text-number {
    display: flex;
    background: #ffffff;
    border: none;
    border-radius: 5px;
    color: #000;
    align-items: center;
    outline: 1px solid #E9E9E7;
    cursor: auto;
}

.text-number > div {
    margin-left: 10px;
}

.text-number > div > span {
    font-size: 14px;
}

.text-number > input {
    width: 75%;
    border: none;
    cursor: pointer;
    padding: 15px 5px;
}